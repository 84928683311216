<template>
	<div>
		<link rel='stylesheet prefetch' href='/css/normalize.css'>
		<section id="header">
			<nav class="fluid-container" role='navigation'>
				<div class="row">
					<div id="logo" class="pull-m-left">

					</div>

				</div>
			</nav>
			<p>皆是修行，莫要耽误</p>
		</section>
		<section class="text-block">
			<div class="container">
				<div class="row">
					<div class="col-m-10 col-m-offset-1">
						<h3>也会去思考浪漫和代码</h3>
						<p>“组成我们身体的元素都来源于上一代恒星，我们都是星辰的孩子，两个碳基生物能在这颗地球上相遇、相爱，是一件几率非常非常小的事情。”
							<br />The afterglow so brilliantly aflame , the seagulls fluttering so carefree.
							辉流霞之灼灼兮翱海鸥之悠悠
							<br />
							Flowing clouds and thousands of thousands finally return to the world.
							流霞万千终归天地悠悠
						</p>
					</div>
				</div>
			</div>
		</section>

		<section id="cta">
			<div class="container">
				<div class="row">
					<div class="col-s-12">
						<a href="#" class="btn" onclick="this.innerHTML='一名中二猿'">ayi</a>

					</div>
				</div>
			</div>
		</section>
		<section id="clients" class="text-block">
			<div class="container">
				<div class="row">
					<div class="col-s-12">
						<h3>拒绝懒惰，拒绝躺平，拒绝自我欺骗，拒绝形式主义，拒绝人云亦云，拒绝内卷。
							</h3>

					</div>

				</div>
			</div>
		</section>

		<!-- <footer>
			<div class="container">
				<div class="row">
					<div class="col-m-4"><img src="https://placehold.it/150x150">
						<h3>暂时用不上</h3>
					</div>
					
				</div>
			</div>
		</footer> -->

		<section id="copy">
			<div class="fluid-container">
				<div class="row">
					<div class="push-m-right">
						<p>by ayi毅ee.</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		methods: {



		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	body {
		text-align: left;
		font-family: 'Open Sans', sans-serif;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.container {
		max-width: 60em;
	}

	#logo h1 {
		line-height: 1;
		margin: 0;
		padding: 0;
		color: #fff;
		font-weight: 600;
	}

	#header {
		padding: 1.6em;
		width: 100%;
		height: 100vh;
		background-size: cover;
		background-image: url(../assets/reg_bg_min.jpg);
		background-attachment: fixed;
	}

	#header p {
		text-align: center;
		color: #fff;
		text-transform: uppercase;
		font-weight: 800;
		font-size: 2em;
		position: relative;
		top: 38%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	nav {
		font-size: 90%;
	}

	nav ul {
		list-style: none;
		margin: 0;
	}

	nav ul li {
		display: inline;
		margin-left: .6rem;
	}

	nav ul li a {
		color: #fff;
		text-decoration: none;
	}

	.text-block {
		text-align: center;
		padding: 3.6em 0;
		line-height: 1.6;
	}

	.text-block h3 {
		text-transform: uppercase;
		font-size: 1.6em;
	}

	#cta {
		background-image: url(../assets/preview.gif);
		background-attachment: fixed;
		background-size: cover;
		background-position: center;
		padding: 4em 0;
		text-align: center;
	}

	#cta .btn {
		text-decoration: none;
		color: #000;
		text-transform: uppercase;
		border: 2px solid #000;
		padding: 1em 3em;
	}

	footer {
		color: #ffffff;
		padding: 4em 0;
		background: slategrey;
		text-align: center;
	}

	footer img {
		border-radius: 9999px;
	}

	#copy {
		background: grey;
		padding: .2em 0;
		font-size: 80%;
	}
</style>

import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import home from "../src/components/home.vue";
import about from "../src/components/about.vue";
import text from "../src/components/text.vue"
import music from "../src/components/music.vue"
import shop from "../src/components/shop.vue"
import strange from "../src/components/strange.vue"
// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);



const routes = [{
		path: "/",
		component: home
	},
	{
		path: "/text",
		component: text
	},
	{
		path: "/music",
		component: music
	},
	{
		path: "/shop",
		component: shop
	},
	{
		path: "/strange",
		component: strange
	},
	{
		path: "/about",
		component: about
}]

var router = new VueRouter({
	routes
})
export default router;

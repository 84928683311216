<template>
	<div class="box2">
		<!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
		<!-- router-link 定义点击后导航到哪个路径下 -->
		<ul>
			<li>
				<router-link to="/home">首页</router-link>
			</li>
			<li>
				<router-link to="/music">音乐</router-link>
			</li>
			<li>
				<router-link to="/text">文摘</router-link>
			</li>
			<li>
				<router-link to="/shop">购物</router-link>
			</li>
			<li>
				<router-link to="/strange">套娃</router-link>
			</li>
			<li>
				<router-link to="/about">关于更多</router-link>
			</li>


		</ul>
		<!-- 对应的组件内容渲染到router-view中 -->
		<router-view></router-view>
		<!-- <first></first> -->
		
	</div>
</template>

<script>
	// import HelloWorld from './components/HelloWorld.vue'
	// import first from './components/first.vue'

	export default {
		name: 'App',
		components: {
			// HelloWorld,
			// first
		}
	}
</script>

<style>
	/* 	#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  color: #2c3e50;
  margin-top: 60px;
} */



	.m-padded-mini {
		padding: 0.2em !important;
	}

	.m-padded-tiny {
		padding: 0.3em !important;
	}

	.m-padded-tb-mini {
		padding-top: 0.2em !important;
		padding-bottom: 0.2em !important;
	}

	.m-padded-tb-tiny {
		padding-top: 0.3em !important;
		padding-bottom: 0.3em !important;
	}

	.m-padded-small {
		padding-top: 0.5em !important;
		padding-bottom: 0.5em !important;
	}

	.m-padded-tb {
		padding-top: 1em !important;
		padding-bottom: 1em !important;
	}

	.m-padded-tb-large {
		padding-top: 2em !important;
		padding-bottom: 2em !important;
	}

	.m-padded-tb-big {
		padding-top: 3em !important;
		padding-bottom: 3em !important;
	}

	.m-padded-tb-huge {
		padding-top: 4em !important;
		padding-bottom: 4em !important;
	}

	.m-padded-tb-massive {
		padding-top: 5em !important;
		padding-bottom: 5em !important;
	}

	.m-padded-lr-responsive {
		padding-left: 4em !important;
		padding-right: 4em !important;
	}

	/*text*/
	.m-text {
		font-weight: 300 !important;
		letter-spacing: 1px !important;
		line-height: 1.8;
	}

	.m-text-thin {
		font-weight: 300 !important;
	}

	.m-text-spaced {
		letter-spacing: 1px !important;
	}

	.m-text-lined {
		line-height: 1.8;
	}

	/*margin*/
	.m-margin-tb-tiny {
		margin-top: 0.3em !important;
		margin-bottom: 0.3em !important;
	}

	.m-margin-top-small {
		margin-top: 0.5em !important;
	}

	.m-margin-top {
		margin-top: 1em !important;
	}

	.m-margin-top-large {
		margin-top: 2em !important;
	}

	.m-margin-bottom-small {
		margin-bottom: 0.5em !important;
	}


	/*opacity*/
	.m-opacity-mini {
		opacity: 0.8 !important;
	}

	.m-opacity-tiny {
		opacity: 0.6 !important;
	}

	/*position*/
	.m-right-top {
		position: absolute;
		top: 0;
		right: 0;
	}

	/*display*/
	.m-inline-block {
		display: inline-block !important;
	}

	/*container*/
	.m-container {
		max-width: 72em !important;
		margin: auto !important;
	}

	.m-container-small {
		max-width: 60em !important;
		margin: auto !important;
	}

	/*shadow*/
	.m-shadow-small {
		-webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
	}


	/*color*/
	.m-black {
		color: #333 !important;
	}

	.m-mobile-show {
		display: none !important;
	}

	@media screen and (max-width: 768px) {
		.m-mobile-hide {
			display: none !important;
		}

		.m-mobile-show {
			display: block !important;
		}

		.m-padded-lr-responsive {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.m-mobile-lr-clear {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.m-mobile-wide {
			width: 100% !important;
		}
	}

	.box2 {}

	.box2 ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		overflow: hidden;
		border: 1px solid #e7e7e7;
		background-color: #f3f3f3;
	}

	.box2 li {
		float: left;
	}

	.box2 li a {
		display: block;
		color: #666;
		text-align: center;
		padding: 14px 16px;
		text-decoration: none;
	}

	.box2 li a:hover:not(.active) {
		background-color: #ddd;
	}

	.box2 li a.active {
		color: white;
		background-color: #4CAF50;
	}

	.box3 {
		border: solid 1px antiquewhite;
		width: 500px;
		height: 520px;
		background-color: white;
		margin: auto;
		margin-top: 6.25rem;
		opacity: 0.8;
		/* 设置边框圆角 */
		border-radius: 20px;
	}
	
</style>

<template>
	<div>
		<div class="container">
			<div class="tablet-wrap">
				<div class="tablet">
					<div>
						<div class="light"></div>
						<div class="trackpad" id="trackpad">
							<div></div>
						</div>
						<div class="speaker">
							<div></div>
						</div>
						<div class="screen">
							<div>
								<iframe class="video" width="98%" height="98%" src="/uploads/141201/among_the_stars.mp4"
									frameborder="0" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</div>
				<div class="tablet-flare"></div>
				<div class="tablet-shadow">
					<div></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'music',
		props: {
			msg: String
		},
		methods: {



		}
	}
</script>

<style>
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	.screen .video {
		position: absolute;
		left: 50%;
		top: 50%;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
	}

	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	html {
		box-sizing: border-box;
	}

	* {
		box-sizing: inherit;
	}

	*:before,
	*:after {
		/**
 * Computes a top-shadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
		/**
 * Computes a bottom-shadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
		/**
 * Gives a card depth effect.
 *
 * @param {Number} $depth - depth level (between 1 and 5)
 *
 * @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
 *
 * @requires {function} top-shadow
 * @requires {function} bottom-shadow
 */

		box-sizing: inherit;
	}

	body {
		background: #1d384c;
	}

	.container {
		max-width: 633px;
		margin: 25px auto 0;
	}

	.tablet-wrap {
		position: relative;
	}

	.tablet,
	.tablet-shadow {
		position: relative;
		border-radius: 25px;
		width: 100%;
	}

	.tablet:before,
	.tablet-shadow:before {
		display: block;
		content: " ";
		width: 100%;
		padding-top: 64.77093%;
	}

	.tablet>*,
	.tablet-shadow>* {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.tablet {
		background: #2c3134;
		position: relative;
		z-index: 1;
	}

	.tablet-shadow {
		background: rgba(26, 26, 26, 0.2);
		position: absolute;
		top: 0;
		left: 0;
		-webkit-transform: translate(-1%, 1.5%);
		-ms-transform: translate(-1%, 1.5%);
		transform: translate(-1%, 1.5%);
		z-index: 0;
	}

	.trackpad,
	.speaker {
		position: relative;
		background: #191d1f;
		border-radius: 50%;
		box-shadow: inset 0px 4px 0px 0px rgba(1, 2, 2, 0.32);
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.trackpad:before,
	.speaker:before {
		display: block;
		content: " ";
		width: 100%;
		padding-top: 100%;
	}

	.trackpad>*,
	.speaker>* {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.trackpad {
		left: 16px;
		width: 7.109%;
	}

	.speaker {
		right: 29px;
		width: 2.12%;
	}

	.screen {
		position: relative;
		background: #000;
		position: absolute;
		left: 75px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: 77.093%;
		z-index: 3;
	}

	.screen:before {
		display: block;
		content: " ";
		width: 100%;
		padding-top: 75.40984%;
	}

	.screen>* {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.light {
		border-radius: 0 25px 25px 0;
		height: 100%;
		overflow: hidden;
		position: absolute;
		right: 0;
		top: 0;
		width: 49.447%;
		z-index: 2;
	}

	.light:before {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		background: rgba(255, 255, 255, 0.078);
		content: '';
		height: 100%;
		position: absolute;
		right: 0;
		-webkit-transform: skewX(20deg) translateX(40%);
		-ms-transform: skewX(20deg) translateX(40%);
		transform: skewX(20deg) translateX(40%);
		width: 120%;
	}
</style>
